import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Button,
  Card as CardUI,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  useTheme
} from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import {useEffect, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
  getAccountPendingTransactions,
  getAccountTransactions,
  getCardPendingTransactions,
  getCardTransactions
} from '../../api/projectmyfinance.api';
import {AppState} from '../../models/models';
import {appSlice} from '../../store/reducer';
import {store} from '../../store/store';
import {classes} from './transactionsTable.styles';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export function TransactionsTable(props: any) {
  const navigate = useNavigate();
  const { type, id } = useParams();

  useEffect(() => {
    store.dispatch(appSlice.actions.clearTransactions())

    if (type === 'account') {
      if (id) {
        getAccountPendingTransactions(id);
        getAccountTransactions(id);
      }
      } else {
        if (id) {
          getCardPendingTransactions(id);
          getCardTransactions(id);
        }
      }
  }, [id, type]);

  const transactions =
    useSelector((state: AppState) =>
      type === 'account' ? state.transactions : state.cardTransactions
    ) ?? [];

  const groupedTransactions: any = [];

  transactions.forEach((transaction, i) => {
    let description = transaction.merchant_name;

    if (description === null) {
      description = transaction.description;
    }

    if (!groupedTransactions[description]) {
      groupedTransactions[description] = {
        description,
        totalAmount: 0,
        transactions: []
      };
    }

    groupedTransactions[description].transactions.push(transaction);
    groupedTransactions[description].totalAmount += transaction.amount;
  });

  const pendingTransactions =
    useSelector((state: AppState) =>
      type === 'account'
        ? state.pendingTransactions
        : state.pendingCardTransactions
    ) ?? [];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
        0,
        (1 + page) * rowsPerPage -
        (pendingTransactions.length + transactions.length)
      )
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function closeTransactions() {
    navigate('/');
  }

  return (
    <Container maxWidth="xl" sx={classes.container}>
      <Grid item xs={12}>
        <CardUI>
          <CardHeader
            sx={{ paddingBottom: 0 }}
            titleTypographyProps={{ variant: 'body1' }}
            subheaderTypographyProps={{ variant: 'body2' }}
            title={'Transactions'}
            action={<Button sx={classes.close} variant="contained" color="primary" onClick={closeTransactions}>Back</Button>}
          />
          <CardContent>
            {' '}
            <TableContainer>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableBody>
                  {(rowsPerPage > 0
                      ? pendingTransactions.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : pendingTransactions
                  ).map((row) => (
                    <TableRow key={row.transaction_id}>
                      <TableCell component="th" scope="row">
                        {row.description}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="right">
                        <NumericFormat
                          displayType="text"
                          value={row.amount}
                          prefix={
                            row.currency
                              ? getSymbolFromCurrency(row.currency)
                              : getSymbolFromCurrency('GBP')
                          }
                          thousandSeparator={true}
                        />
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="right">
                        Pending
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="right">
                        {new Date(row.timestamp).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {(rowsPerPage > 0
                      ? transactions.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : transactions
                  ).map((row) => (
                    <TableRow key={row.transaction_id}>
                      <TableCell component="th" scope="row">
                        {row.description}
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="right">
                          <NumericFormat
                            displayType="text"
                            value={row.amount}
                            prefix={
                              row.currency
                                ? getSymbolFromCurrency(row.currency)
                                : getSymbolFromCurrency('GBP')
                            }
                            thousandSeparator={true}
                          />
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="right">
                          <NumericFormat
                            displayType="text"
                            value={
                              row.running_balance
                                ? row.running_balance.amount
                                : undefined
                            }
                            prefix={
                              row.running_balance &&
                              row.running_balance.currency
                                ? getSymbolFromCurrency(
                                  row.running_balance.currency
                                )
                                : getSymbolFromCurrency('GBP')
                            }
                            thousandSeparator={true}
                          />
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="right">
                        {new Date(row.timestamp).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        25,
                        { label: 'All', value: -1 }
                      ]}
                      colSpan={4}
                      count={pendingTransactions.length + transactions.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page'
                        },
                        native: true
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>
        </CardUI>
      </Grid>
    </Container>
  );
}
