import {
    Box,
    Button,
    Card as CardUI,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {getAllTransactions, searchTransactions} from "../../api/projectmyfinance.api";
import SearchIcon from "@mui/icons-material/Search";
import {theme} from "../../util/theme";
import {useNavigate} from "react-router-dom";
import {store} from "../../store/store";
import {appSlice} from "../../store/reducer";
import {useSelector} from "react-redux";
import {AppState} from "../../models/models";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import getSymbolFromCurrency from 'currency-symbol-map';
import {NumericFormat} from 'react-number-format';
import {classes} from './transactionsTable.styles';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


export function Transactions() {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const transactions = useSelector((state: AppState) => state.allTransactions);

    useEffect(() => {
        store.dispatch(appSlice.actions.clearTransactions())
        getAllTransactions()
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const emptyRows =
        page > 0
            ? Math.max(
                0,
                (1 + page) * rowsPerPage -
                (transactions ? transactions.length : 0)
            )
            : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function closeTransactions() {
        navigate('/');
    }

    const handleSearch = async (value) => {
        try {
            let transactions = await searchTransactions(value);
            setResults(transactions);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    useEffect(() => {
        if (searchTerm.length > 2) {
            handleSearch(searchTerm);
        } else {
            setResults([]);
        }
    }, [searchTerm]);

    return (
        <><TextField
            sx={{
                maxWidth: "50%",
                [theme.breakpoints.down('md')]: {
                    maxWidth: "100%",
                }
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
            }}
            placeholder="Search transactions..."
            fullWidth/><List sx={{
            position: "absolute",
            zIndex: theme.zIndex.modal + 1
        }}>
            {results && results.map((transaction: any, index) => (
                <ListItem
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                            cursor: 'pointer',
                        },
                        '&:active': {
                            backgroundColor: theme.palette.action.selected,
                        },
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: theme.shape.borderRadius,
                        margin: theme.spacing(0.5, 0),
                    }}
                    key={index}
                >
                    <ListItemText
                        primary={`${transaction.description} - ${new Date(transaction.timestamp).toLocaleDateString()}`}
                        secondary={<Chip
                            sx={{
                                backgroundColor: transaction.amount > 0 ? 'green' : '#007fff',
                                color: "white"
                            }}
                            label={`£${transaction.amount.toFixed(2)}`}/>}/>
                </ListItem>
            ))}
        </List>


            <Container maxWidth="xl" sx={classes.container}>
                <Grid item xs={12}>
                    <CardUI>
                        <CardHeader
                            sx={{paddingBottom: 0}}
                            titleTypographyProps={{variant: 'body1'}}
                            subheaderTypographyProps={{variant: 'body2'}}
                            title={'Transactions'}
                            action={<Button sx={classes.close} variant="contained" color="primary"
                                            onClick={closeTransactions}>Back</Button>}
                        />
                        <CardContent>
                            {' '}
                            <TableContainer>
                                <Table
                                    sx={{minWidth: 500}}
                                    aria-label="custom pagination table"
                                >
                                    <TableBody>
                                        {(transactions ? rowsPerPage > 0
                                                ? transactions.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                : transactions : []
                                        ).map((row) => (
                                            <TableRow key={row.transaction_id}>
                                                <TableCell component="th" scope="row">
                                                    {row.description}
                                                </TableCell>

                                                <TableCell style={{width: 160}} align="right">
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={row.amount}
                                                        prefix={
                                                            row.currency
                                                                ? getSymbolFromCurrency(row.currency)
                                                                : getSymbolFromCurrency('GBP')
                                                        }
                                                        thousandSeparator={true}
                                                    />
                                                </TableCell>

                                                <TableCell style={{width: 160}} align="right">
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={
                                                            row.running_balance
                                                                ? row.running_balance.amount
                                                                : undefined
                                                        }
                                                        prefix={
                                                            row.running_balance &&
                                                            row.running_balance.currency
                                                                ? getSymbolFromCurrency(
                                                                    row.running_balance.currency
                                                                )
                                                                : getSymbolFromCurrency('GBP')
                                                        }
                                                        thousandSeparator={true}
                                                    />
                                                </TableCell>

                                                <TableCell style={{width: 160}} align="right">
                                                    {new Date(row.timestamp).toLocaleDateString()}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 53 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[
                                                    10,
                                                    25,
                                                    {label: 'All', value: -1}
                                                ]}
                                                colSpan={4}
                                                count={transactions ? transactions.length : 0}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label': 'rows per page'
                                                    },
                                                    native: true
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </CardUI>
                </Grid>
            </Container>
        </>
    );
}