import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Link as StyledLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addPartner, deleteToken, getAvatar, getCurrentUser, getProfileSettings, getProviders, getTokens, setProfileSettings, uploadAvatar } from '../../api/projectmyfinance.api';
import { AppState, PartnerUser, Provider, Settings } from '../../models/models';
import { classes } from './settings.styles';
import { getInitials } from '../../util/getInitials';
import { Confirmation } from '../../components/common/confirmation';

export function AccountSettings() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tokens = useSelector((state: AppState) => state.tokens);
  const avatar = useSelector((state: AppState) => state.avatar);
  const providers = useSelector((state: AppState) => state.providers);
  const currentUser = useSelector((state: AppState) => state.currentUser);
  const settings = useSelector((state: AppState) => state.settings);
  const partners = useSelector((state: AppState) => state.partners);
  const [settingsForm, setSettingsForm] = useState<Settings>(settings);
  const [partnerEmail, setPartnerEmail] = useState<string>();
  const [expandedTokens, setExpandedTokens] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggleExpand = (tokenId: string) => {
    setExpandedTokens((prevState) => ({
      ...prevState,
      [tokenId]: !prevState[tokenId],
    }));
  };

  useEffect(() => {
    getCurrentUser();
    getProfileSettings();
    getAvatar();
    getTokens();
    getProviders();
  }, []);

  useEffect(() => {
    setSettingsForm(settings);
  }, [settings]);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setProfileSettings({
      firstName: settingsForm.firstName,
      middleNames: settingsForm.middleNames,
      lastName: settingsForm.lastName,
      birthDate: settingsForm.birthDate,
    });
  };

  const [imageLoadError, setImageLoadError] = useState(false);
  const [partnerImageLoadError, setPartnerImageLoadError] = useState(false);

  const handleImageError = () => {
    setImageLoadError(true);
  };

  const handlePartnerImageError = () => {
    setPartnerImageLoadError(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target && e.target.files && e.target.files[0];
    if (file) {
      uploadAvatar(file);
    }
  };

  return (
    <Container maxWidth="xl" sx={classes.container}>
      <form noValidate onSubmit={handleSubmit}>
        <Paper sx={classes.paper}>
          <Box sx={{ width: '100%', maxWidth: 500, marginBottom: 3 }}>
            <Typography variant="h3">My Account</Typography>
          </Box>
          <Grid container rowSpacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    key={currentUser.email}
                    label="Email"
                    color="primary"
                    variant="outlined"
                    defaultValue={currentUser.email}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={settings.firstName}
                    label="First Name"
                    color="primary"
                    variant="outlined"
                    defaultValue={settings && settings.firstName}
                    onChange={(e) =>
                      setSettingsForm({
                        ...settingsForm,
                        firstName: e.currentTarget.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={settings.middleNames}
                    label="Middle Names"
                    color="primary"
                    variant="outlined"
                    defaultValue={settings.middleNames && settings.middleNames}
                    onChange={(e) =>
                      setSettingsForm({
                        ...settingsForm,
                        middleNames: e.currentTarget.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={settings.lastName}
                    label="Last Name"
                    color="primary"
                    variant="outlined"
                    defaultValue={settings.lastName && settings.lastName}
                    onChange={(e) =>
                      setSettingsForm({
                        ...settingsForm,
                        lastName: e.currentTarget.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MobileDatePicker
                    key={settings.birthDate}
                    label="Birthday"
                    inputFormat="dd/MM/yyyy"
                    value={new Date(settingsForm.birthDate)}
                    onChange={(date: Date | null) =>
                      date &&
                      setSettingsForm({
                        ...settingsForm,
                        birthDate: date.toString(),
                      })
                    }
                    renderInput={(params: any) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container justifyContent="center" alignItems="center">
                <Avatar aria-label="Profile Picture" sx={classes.avatar}>
                  {!imageLoadError && (
                    <img
                      alt="avatar"
                      src={avatar}
                      height={100}
                      onError={handleImageError}
                    />
                  )}
                  {(!avatar || imageLoadError) &&
                    getInitials(
                      `${settings?.firstName} ${settings?.middleNames} ${settings?.lastName}`
                    )}
                </Avatar>
                <Grid container justifyContent="center" alignItems="center">
                  <Button
                    sx={classes.upload}
                    variant="outlined"
                    component="label"
                  >
                    Upload Image
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e)}
                      hidden
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            marginTop={3}
            columns={{ xs: 6, md: 6, lg: 6 }}
          >
            <Link
              to="/reset"
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <StyledLink href="#" variant="body2">
                Change password
              </StyledLink>
            </Link>
            <Button
              type="submit"
              variant="contained"
              color={'primary'}
              sx={classes.button}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </form>

      {tokens && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', maxWidth: 500, marginTop: 3 }}>
              <Typography variant="h5">Connections</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Provider</TableCell>
                    <TableCell>Updated Last</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...tokens]
                    .sort((a, b) => a.id.localeCompare(b.id))
                    .map((token) => {
                      const isExpanded = expandedTokens[token.id] || false;
                      return (
                        <React.Fragment key={token.id}>
                          <TableRow>
                            <TableCell
                              sx={classes.ellipsis}
                              component="th"
                              scope="row"
                            >
                              {
                                providers?.find(
                                  (provider: Provider) =>
                                    provider?.provider_id === token?.provider_id
                                )?.display_name
                              }
                            </TableCell>
                            <TableCell sx={classes.ellipsis}>
                              {token.updated_at &&
                                new Date(token.updated_at).toLocaleString()}
                            </TableCell>

                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => handleToggleExpand(token.id)}
                              >
                                {isExpanded ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => setIsModalOpen(true)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <Confirmation
                            text="Are you sure you want to delete this token?"
                            actionText="Delete"
                            onAction={() => deleteToken(token.id)}
                            open={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                          />
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              sx={{ padding: 0, borderBottom: 'none' }}
                            >
                              <Collapse
                                sx={{ background: 'lightgrey' }}
                                in={isExpanded}
                                timeout="auto"
                                unmountOnExit
                              >
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}  <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%', maxWidth: 500, marginTop: 3 }}>
            <Typography variant="h5">Partner(s)</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {partners?.map((partner: PartnerUser) => {
            return (
              <Card sx={classes.paper} key={partner.email}>
                {/* Partner Header */}
                <CardHeader
                  titleTypographyProps={{ variant: 'body1' }}
                  subheaderTypographyProps={{ variant: 'body2' }}
                  title={
                    partner.email
                  }
                  avatar={
                    <Avatar
                      aria-label="Profile Picture"
                      sx={classes.avatarPartner}
                    >
                      {/* Partner Avatar */}
                      {partner.avatar && !partnerImageLoadError && (
                        <img
                          alt="partner-avatar"
                          src={partner.avatar}
                          height={50}
                          onError={handlePartnerImageError}
                        />
                      )}
                    </Avatar>
                  }
                />
              </Card>
            );
          })}

        </Grid>

        <Grid item xs={12}>
          <Paper sx={classes.paper}>
            <TextField
              label="Partner Email"
              color="primary"
              variant="outlined"
              onChange={(e) => setPartnerEmail(e.currentTarget.value)}
              fullWidth
            />
            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                color={'primary'}
                onClick={() => partnerEmail && addPartner(partnerEmail)}
                sx={classes.button}
              >
                Add Partner
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
