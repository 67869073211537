import { createTheme } from '@mui/material';

const theme = createTheme();

export const classes = {
  paper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      maxWidth: 'none',
      top: '50%',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
};
