import { Box, Card, CardHeader, Grid, Skeleton, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AccountData, AppState } from '../../models/models';
import { getTruelayerAccountBalance } from '../../api/projectmyfinance.api';
import {BlackOut} from "../common/blackout";

const classes = {
  card: {
    border: 'transparent solid 1px',
    '&:hover': {
      border: '#000 solid 1px',
      cursor: 'pointer',
    },
    padding: { xs: '4px', sm: '8px' },
    fontSize: { xs: '0.75rem', sm: '1rem' },
  },
};

export interface AccountCardProps {
  index: number;
  accountData?: AccountData;
  openTransactions: (accountId: string) => void;
}

export function AccountCard(props: AccountCardProps) {
  const { accountData } = props;
  const accountBalance = useSelector((state: AppState) => state.accountBalances);
  const providers = useSelector((state: AppState) => state.providers);

  useEffect(() => {
    if (accountData) getTruelayerAccountBalance(accountData.account_id)
  }, [accountData]);

  const title = accountData && accountData.display_name.length > 40 ? `${accountData.display_name.slice(0, 37)}...` : accountData && accountData.display_name;

  return (
    <Grid item xs={12}>
      {accountData ?
        (<Card
          sx={classes.card}
          onClick={() => accountData && props.openTransactions(accountData.account_id)}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3} sm={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', maxWidth: "200px" }}>
                <img
                  src={providers && providers.find(provider => provider.provider_id === accountData.provider.provider_id)?.logo_url}
                  width={90} // Adjusted width for mobile
                  alt="Provider Logo"
                />
              </Box>
            </Grid>
            <Grid item xs={8} sm={5}>
              <CardHeader
                sx={{
                  textAlign: 'left',
                  padding: '0px' // Remove default padding for better spacing
                }}
                titleTypographyProps={{ variant: 'body1', noWrap: true }}
                subheaderTypographyProps={{ variant: 'body2', noWrap: true }}
                title={title}
                subheader={accountData && `${accountData.account_type} | ${accountData.account_number.number} | ${accountData.account_number.sort_code}`}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <CardHeader
                sx={{ textAlign: 'right', padding: '0px' }} // Remove default padding for better spacing
                titleTypographyProps={{ variant: 'h6', noWrap: true }} // Smaller font size for mobile
                subheaderTypographyProps={{ variant: 'body2', noWrap: true }} // Smaller font size for mobile
                title={
                  <>
                    <Typography variant='h6'>
                      <BlackOut>
                        <NumericFormat
                          displayType="text"
                          prefix={"£"}
                          value={accountBalance?.find(balance => balance.account_id === accountData.account_id)?.current ?? 0}
                          thousandSeparator={true}
                        />
                      </BlackOut>
                    </Typography>
                    <Typography variant='body2'>
                      <span>Available: </span>
                      <BlackOut>
                        <NumericFormat
                          displayType="text"
                          prefix={"£"}
                          value={accountBalance?.find(balance => balance.account_id === accountData.account_id)?.available ?? 0}
                          thousandSeparator={true}
                        />
                      </BlackOut>
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Card>) :
        <Skeleton variant="rectangular" height={90} sx={{ width: '100%' }} animation={"wave"} />}
    </Grid>
  );
}