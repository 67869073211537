import { createTheme } from '@mui/material';

const theme = createTheme();

export const classes = {
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  close: {
    backgroundColor: theme.palette.primary.main,
  }
};
