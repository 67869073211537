 import LockOutlined from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { classes } from './signIn.styles';
import Cookies from 'js-cookie';
import { AppState } from '../../../models/models';
import { store } from '../../../store/store';
import { appSlice } from '../../../store/reducer';
import { signIn } from '../../../api/projectmyfinance.api';


export interface creds {
  email: string;
  password: string;
}

export function SignIn() {
  const state = useSelector((state: AppState) => state);
  const [creds, setCreds] = React.useState<creds>({
    email: '',
    password: '',
  });

  //if cookie exists and oidc
  if(Cookies.get("accessToken")) {
    store.dispatch(appSlice.actions.setAuthenticated(true));
  }


  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreds({ ...creds, [event.target.id]: event.target.value } as Pick<
      creds,
      keyof creds
    >);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    signIn(creds.email, creds.password);
  };

  if (state.isAuthenticated) {
    return <Navigate to={'/'} />;
  }

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl sx={classes.form}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  autoComplete="email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              id="sign-in"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Sign In
            </Button>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction={'column'}
              rowSpacing={1}
            >
              <Grid item xs>
                <Link
                  to="/reset"
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Button
                    id="forget-password"
                    variant="contained"
                    color="secondary"
                  >
                    Forgot password?
                  </Button>
                </Link>
              </Grid>
              <Grid item xs>
                <Link
                  to="/sign-up"
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Button id="sign-up" variant="contained" color="secondary">
                    {"Don't have an account? Sign Up"}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Box>
    </Container>
  );
}
