import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { CircularProgress } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// Mock credit score for demonstration
const mockCreditScore = 720;

// Function to determine credit category
function getCreditCategory(score) {
    if (score >= 800) return 'Excellent';
    if (score >= 740) return 'Very Good';
    if (score >= 670) return 'Good';
    if (score >= 580) return 'Fair';
    return 'Poor';
}

const CreditScore = () => {
    const [loading, setLoading] = useState(true);
    const [score, setScore] = useState(0);

    // Simulate fetching credit score
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setScore(mockCreditScore);
            setLoading(false);
        }, 1000); // Simulate API call delay
        return () => clearTimeout(timer);
    }, []);

    const category = getCreditCategory(score);

    // Color based on score
    const progressColor = () => {
        if (score >= 800) return 'success';
        if (score >= 740) return 'primary';
        if (score >= 670) return 'warning';
        if (score >= 580) return 'error';
        return 'error';
    };

    const creditScoreColor = progressColor();

    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" gutterBottom>Credit Score</Typography>

            <Item>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2" color={creditScoreColor}>
                                {score}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" color={creditScoreColor}>
                                {category}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(score / 850) * 100}
                                color={creditScoreColor}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Your credit score is a numerical expression based on a level analysis of your credit files, to represent your creditworthiness. Here's your breakdown:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2">Poor</Typography>
                                <Typography variant="body2">Fair</Typography>
                                <Typography variant="body2">Good</Typography>
                                <Typography variant="body2">Very Good</Typography>
                                <Typography variant="body2">Excellent</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                <Typography variant="body2">300</Typography>
                                <Typography variant="body2">580</Typography>
                                <Typography variant="body2">670</Typography>
                                <Typography variant="body2">740</Typography>
                                <Typography variant="body2">800</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Item>
        </Box>
    );
};

export default CreditScore;