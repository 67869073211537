import { theme } from "../../../util/theme";


export const classes = {
  paper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%', // Default width for larger screens
    maxWidth: 500, // Maximum width for larger screens
    [theme.breakpoints.down('sm')]: {
      width: '90%', // Width for small devices
      maxWidth: 'none', // No maximum width on small devices
      top: '50%', // Adjust if needed for content height
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
   form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
};
