import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {AppState} from '../../models/models';
import {appSlice} from '../../store/reducer';
import {store} from '../../store/store';
import {getInitials} from '../../util/getInitials';
import {Drawer, Switch, useTheme} from '@mui/material';
import {
    AccountBalance, Brightness4, Brightness7,
    CurrencyBitcoin,
    FindInPage,
    Scoreboard,
    SpaceDashboard,
    ViewList,
    ViewStream
} from "@mui/icons-material";

const pages = [{name: 'Dashboard', icon: <SpaceDashboard />}, {name: 'Accounts', icon: <AccountBalance />}, { name:'Report', icon: <FindInPage />}, {name: "Transactions", icon: <ViewList />}, { name: "Borrowing", icon: <ViewStream />}, {name: "Credit Score", icon: <Scoreboard />}, { name: "Crypto", icon: <CurrencyBitcoin />}];
const settings = ['Settings'];

export function Navbar({children}) {
    const theme = useTheme();
    const state = useSelector((state: AppState) => state);
    const avatar = useSelector((state: AppState) => state.avatar);
    const darkMode = useSelector((state: AppState) => state.darkMode);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function logout() {
        Cookies.remove('accessToken');
        store.dispatch(appSlice.actions.setAuthenticated(false));
    }

    function handleThemeToggle(darkMode: boolean) {
        store.dispatch(appSlice.actions.setDarkMode(darkMode))
    }

    return (
        <Box sx={{display: 'flex'}}>
            {/* Sidebar */}
            <Drawer
                sx={{
                    width: "240px",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "240px",
                        boxSizing: 'border-box',
                    },
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    },
                }}
                variant="permanent"
                anchor="left"
                open
            >
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        color: theme.palette.primary.main,
                        mr: 2,
                        display: {xs: 'none', md: 'flex'},
                        fontFamily: 'Segoe UI',
                        fontWeight: 700,
                        fontSize: 18,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        px: 3,
                        py: 3
                    }}
                >
                    Project My Finance
                </Typography>
                <Box sx={{overflow: 'auto'}}>
                    {pages.map((page) => (
                        <Button
                            key={page.name}
                            component={Link}
                            startIcon={page.icon}
                            to={page.name.toLowerCase() === "dashboard" ? `/` : `/${page.name.toLowerCase()}`}
                            sx={{
                                color: theme.palette.secondary.contrastText,
                                ...(window.location.pathname === (page.name.toLowerCase() === "dashboard" ? "/" : `/${page.name.toLowerCase()}`) && {
                                    color: theme.palette.primary.main
                                }),
                                justifyContent: 'flex-start',
                                px: 3,
                                textAlign: 'left',
                                fontFamily: "Segoe UI",
                                fontWeight: 700,
                                fontSize: 20,
                                width: '100%',
                                position: 'relative'
                            }}
                        >
                            {page.name}
                        </Button>
                    ))}
                </Box>
                <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
                    <IconButton color="inherit">
                        {darkMode ? <Brightness7 /> : <Brightness4 />}
                    </IconButton>
                    <Switch checked={darkMode} onChange={() => handleThemeToggle(!darkMode)} />
                </Box>
            </Drawer>

            {/* Mobile Sidebar */}
            <Drawer
                sx={{
                    display: {xs: 'block', md: 'none'},
                }}
                anchor="left"
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
            >
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: theme.palette.primary.main
                    }}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setSidebarOpen(false)}
                            sx={{mr: 2, display: {md: 'none'}, color: "white"}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                textAlign: "center",
                                mr: 2,
                                display: {md: 'flex'},
                                fontFamily: 'Segoe UI',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'white',
                                textDecoration: 'none',
                                px: 3,
                                py: 3
                            }}
                        >
                            Project My Finance
                        </Typography>
                    </Toolbar>
                    {pages.map((page) => (
                        <Button
                            key={page.name}
                            component={Link}
                            to={page.name.toLowerCase() === "dashboard" ? `/` : `/${page.name.toLowerCase()}`}
                            onClick={() => setSidebarOpen(false)}
                            sx={{
                                color: 'white',
                                justifyContent: 'flex-start',
                                px: 3,
                                fontFamily: "Segoe UI",
                                textAlign: 'left',
                                width: '100%'
                            }}
                        >
                            {page.name}
                        </Button>
                    ))}
                </Box>
            </Drawer>

            {/* Main Content */}
            <Box component="main" sx={{
                flexGrow: 1,
                p: 3,
                [theme.breakpoints.down('md')]: {
                    flexGrow: 0,
                    p: 0,
                }
            }}>
                <Toolbar />
                <Box sx={{marginLeft: {md: "240px"}}}>
                    <AppBar elevation={0} color={"secondary"} sx={{paddingLeft: {md: "240px"}}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => setSidebarOpen(true)}
                                sx={{mr: 2, display: {md: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Box sx={{flexGrow: 1}}/>
                            <Box sx={{flexGrow: 0, marginLeft: 1}}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar src={avatar} sx={{width: 36, height: 36}}>
                                            {getInitials(`${state.settings?.firstName} ${state.settings?.middleNames} ${state.settings?.lastName}`)}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={handleCloseUserMenu} component={Link}
                                                  to={`/${setting.toLowerCase()}`}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                    <MenuItem key="logout" onClick={() => {
                                        handleCloseUserMenu();
                                        logout();
                                    }}>
                                        <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
                {children}
            </Box>
        </Box>
    );
}