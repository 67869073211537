import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from "../../models/models";

export function BlackOut({children}: { children: any }) {
    const isBlackOut = useSelector((state: AppState) => state.blackout);

    // Dynamic styling based on the state
    const style: React.CSSProperties = {
        backgroundColor: isBlackOut ? 'black' : 'transparent',
        color: isBlackOut ? 'black' : 'inherit',
        display: 'inline-block',
        padding: '0 2px',
    };

    return (
        <span style={style}>
            {children}
            </span>
    );
}