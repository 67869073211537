import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import {LineChart} from '@mui/x-charts/LineChart';

// Mock data for demonstration
const mockCryptoData = [
    { id: 1, name: 'Bitcoin', symbol: 'BTC', price: 35000, change: 2.5, holdings: 0.1 },
    { id: 2, name: 'Ethereum', symbol: 'ETH', price: 2500, change: 1.8, holdings: 2 },
    { id: 3, name: 'Cardano', symbol: 'ADA', price: 1.5, change: -0.2, holdings: 100 },
    // Add more cryptocurrencies as needed
];

// Mock price history for demonstration
const mockPriceHistory = {
    'BTC': [30000, 31000, 32000, 33000, 34000, 35000],
    'ETH': [2000, 2100, 2200, 2300, 2400, 2500],
    'ADA': [1.2, 1.3, 1.4, 1.3, 1.4, 1.5]
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Crypto = () => {
    const [cryptoData, setCryptoData]: any = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate fetching crypto data from an API
        setTimeout(() => {
            setCryptoData(mockCryptoData);
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" gutterBottom>Crypto Portfolio</Typography>

            <Item>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="crypto table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Symbol</TableCell>
                                        <TableCell align="right">Price (USD)</TableCell>
                                        <TableCell align="right">Holdings</TableCell>
                                        <TableCell align="right">Value (USD)</TableCell>
                                        <TableCell align="right">24h Change (%)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cryptoData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.symbol}</TableCell>
                                            <TableCell align="right">{row.price.toFixed(2)}</TableCell>
                                            <TableCell align="right">{row.holdings}</TableCell>
                                            <TableCell align="right">{(row.price * row.holdings).toFixed(2)}</TableCell>
                                            <TableCell align="right"
                                                       style={{ color: row.change >= 0 ? 'green' : 'red' }}>
                                                {row.change.toFixed(2)}%
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Graph for each cryptocurrency */}
                        {cryptoData.map((crypto) => (
                            <Box key={crypto.id} sx={{ mt: 3 }}>
                                <Typography variant="h6">{crypto.name} Price History</Typography>
                                <LineChart
                                    xAxis={[{ data: [1, 2, 3, 4, 5, 6], scaleType: 'linear' }]}
                                    series={[
                                        {
                                            data: mockPriceHistory[crypto.symbol],
                                            label: `${crypto.symbol} Price`,
                                            showMark: false
                                        }
                                    ]}
                                    width={500}
                                    height={300}
                                />
                            </Box>
                        ))}
                    </>
                )}
            </Item>
        </Box>
    );
};

export default Crypto;