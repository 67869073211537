import axios, {AxiosError, AxiosResponse} from 'axios';
import Cookies from 'js-cookie';
import {AccountBalance, CardBalance, PartnerUser, Settings} from '../models/models';
import {appSlice} from '../store/reducer';
import {store} from '../store/store';

// Create a new instance of Axios
const api = axios.create({
  baseURL: process.env['REACT_APP_BACKEND_URL'],
});

// Add a response interceptor for successful responses
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    handleAxiosError(error);
    return Promise.reject(error);
  }
);

// Global error handler for Axios errors
const handleAxiosError = (error: AxiosError) => {
  const statusCode = error.response ? error.response.status : null;
  store.dispatch(
    appSlice.actions.setErrorState({
      enabled: true,
      message: `Unknown Error Occurred (Code: ${statusCode})`,
      type: 'error',
    })
  );
};

/**
 * Sign in
 * @param username
 * @param password
 */
export async function signIn(email: string, password: string) {
  api
    .post(`/api/auth/signin`, {
      email: email.toLowerCase(),
      password: password,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        Cookies.set('accessToken', response.data.accessToken, { path: '/' });
        store.dispatch(appSlice.actions.clearState());
        store.dispatch(appSlice.actions.setAuthenticated(true));
      }
    })
    .catch((error: AxiosError) => {
      if (error.code === 'ERR_NETWORK') {
        store.dispatch(
          appSlice.actions.setErrorState({
            enabled: true,
            message:
              'Project My Finance is currently down. Please try again later.',
            type: 'error',
          })
        );
      }
      if (error.response?.status === 401) {
        store.dispatch(
          appSlice.actions.setErrorState({
            enabled: true,
            message: 'Email or Password is Incorrect. Please try again.',
            type: 'warning',
          })
        );
      }
    });
}

/**
 * Get Current User
 */
export async function getCurrentUser() {
  api
    .get(`/api/auth/user`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        if (response.data) {
          store.dispatch(appSlice.actions.setCurrentUser(response.data));
        }
      }
    })
    .catch((error: AxiosError) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: String(error.response?.data),
          type: 'warning',
        })
      );
    });
}

/**
 * Sign up User to Cognito
 * @param email
 * @param password
 */
export function signUp(email: string, password: string) {
  api
    .post(`/api/auth/signup`, {
      password: password,
      email: email.toLowerCase(),
    })
    .then((response: AxiosResponse) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message:
            'Successfully signed up! Please check email for verification',
          type: 'info',
        })
      );
    })
    .catch((error: AxiosError<{ message: string }>) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: error?.response?.data?.message || 'An error occurred',
          type: 'warning',
        })
      );
    });
}

/**
 * Forget password for User
 */
export function forgotPassword(email: string) {
  api.post(
    `/api/auth/reset-password`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
      params: {
        email,
      },
    }
  );
}

/**
 * Change password for User
 */
export function changePassword(
  email: string,
  token: string,
  password: string,
  repeatPassword: string
) {
  api.post(
    `/api/auth/change-password`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
      params: {
        email,
        token,
        password,
        repeatPassword,
      },
    }
  );
}

/**
 * Get accounts
 */
export function getAccounts() {
  api
    .get(`/api/accounts`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        store.dispatch(appSlice.actions.setAccounts(response.data));
      }
    })
    .catch(() => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get accounts.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get partner accounts
 */
export function getPartnerAccounts() {
    api
        .get(`/api/accounts/partners`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                store.dispatch(appSlice.actions.setPartnerAccounts(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get partner accounts.',
                    type: 'warning',
                })
            );
        });
}

/**
 * Get truelayer account
 */
export function getTruelayerAccount(account_id: string) {
  api
    .get(`/api/truelayer/accounts/${account_id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => store.dispatch(appSlice.actions.setAccountData(response.data)))
    .catch(() => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get accounts.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get truelayer account balance
 */
export function getTruelayerAccountBalance(account_id: string): void {
  api
    .get(`/api/truelayer/accounts/${account_id}/balance`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse<AccountBalance>) => store.dispatch(appSlice.actions.setAccountBalance({...response.data, account_id: account_id})))
    .catch(() => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get accounts.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get all transactions
 */
export function getAllTransactions() {
    api
        .get(`/api/transactions`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (response.data) {
                store.dispatch(appSlice.actions.setAllTransactions(response.data));
            }
        });
}


/**
 * Get all account transactions
 */
export function getAllAccountTransactions() {
  api
    .get(`/api/truelayer/accounts/transactions`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setAllAccountTransactions(response.data));
      }
    });
}

/**
 * Get account transactions
 */
export function getAccountTransactions(
  accountId: string,
) {
  api
    .get(`/api/truelayer/accounts/${accountId}/transactions`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setAccountTransactions(response.data));
      }
    });
}

/**
 * Get account pending transactions
 */
export function getAccountPendingTransactions(
  accountId: string,
) {
  api
    .get(`/api/truelayer/accounts/${accountId}/transactions/pending`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(
          appSlice.actions.setAccountPendingTransactions(response.data)
        );
      }
    });
}

/**
 * Search transactions
 */
export function searchTransactions(value: string): Promise<any> {
    return api
        .get(`/api/transactions/search/description?q=${encodeURIComponent(value)}`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            return response.data;
        });
}

/**
 * Get tokens
 */
export function getTokens() {
  api
    .get(`/api/tokens`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setTokens(response.data));
      }
    });
}

/**
 * Get tokens
 */
export function getProviders() {
  api
    .get(`/api/truelayer/providers`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setProviders(response.data));
      }
    });
}

/**
 * Delete token
 */
export function deleteToken(id: string) {
  api
    .delete(`/api/token/${id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
}

/**
 * Get cards
 */
export function getCards() {
  api
    .get(`/api/cards`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        store.dispatch(appSlice.actions.setCards(response.data));
      }
    });
}

/**
 * Get partner accounts
 */
export function getPartnerCards() {
    api
        .get(`/api/cards/partners`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                store.dispatch(appSlice.actions.setPartnerCards(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get partner cards.',
                    type: 'warning',
                })
            );
        });
}

/**
 * Get truelayer card
 */
export function getTruelayerCard(account_id: string) {
  api
    .get(`/api/truelayer/cards/${account_id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => store.dispatch(appSlice.actions.setCardData(response.data)))
    .catch(() => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get accounts.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get truelayer accounts
 */
export function getTruelayerCardBalance(account_id: string): void {
  api
    .get(`/api/truelayer/cards/${account_id}/balance`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse<CardBalance>) => store.dispatch(appSlice.actions.setCardBalance({...response.data, account_id: account_id})))
    .catch(() => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get accounts.',
          type: 'warning',
        })
      );
    });
}


/**
 * Get card transactions
 */
export function getAllCardTransactions() {
  api
    .get(`/api/truelayer/cards/transactions`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setAllCardTransactions(response.data));
      }
    });
}

/**
 * Get card transactions
 */
export function getCardTransactions(
  cardId: string,
) {
  api
    .get(`/api/truelayer/cards/${cardId}/transactions`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setCardTransactions(response.data));
      }
    });
}

/**
 * Get card pending transactions
 */
export function getCardPendingTransactions(
  cardId: string,
) {
  api
    .get(`/api/truelayer/cards/${cardId}/transactions/pending`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(
          appSlice.actions.setCardPendingTransactions(response.data)
        );
      }
    });
}

/**
 * Get Profile Settings
 */
export function getProfileSettings() {
  api
    .get(`/api/profile/settings`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setSettings(response.data));
      }
    });
}

/**
 * Get Partners
 */
export function getPartners() {
  api
    .get(`/api/partners`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setPartners(response.data));
        response.data.map((partner: PartnerUser) => {
          return getPartnerAvatar(partner.email);
        });
      }
    });
}

/**
 * Add Partner
 */
export function addPartner(email: string) {
  api.post(
    `/api/partner`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
      params: {
        email,
      },
    }
  );
}

/**
 * Set Profile Settings
 */
export function setProfileSettings(settings: Settings) {
  api.post(
    `/api/profile/settings`,
    {
      ...settings,
    },
    {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    }
  );
}

/**
 * Get Profile Picture
 */
export function getAvatar() {
  api
    .get(`/api/profile/avatar/download`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
      responseType: 'blob',
    })
    .then((response) => {
      if (response) {
        store.dispatch(
          appSlice.actions.setAvatar(
            URL.createObjectURL(new Blob([response.data]))
          )
        );
      }
    })
    .catch((e) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get avatar.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get Partner Profile Picture
 */
export function getPartnerAvatar(email: string) {
  api
    .get(`/api/profile/avatar/partner/download`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
      params: {
        email,
      },
      responseType: 'blob',
    })
    .then((response) => {
      if (response) {
        store.dispatch(
          appSlice.actions.setPartnerAvatar({
            email,
            avatar: URL.createObjectURL(new Blob([response.data])),
          })
        );
      }
    })
    .catch((e) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to get partner avatar.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get Profile Picture
 */
export function uploadAvatar(file: File) {
  api
    .post(
      `/api/profile/avatar/upload`,
      {
        file,
      },
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('accessToken'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .catch((e) => {
      store.dispatch(
        appSlice.actions.setErrorState({
          enabled: true,
          message: 'Failed to upload avatar.',
          type: 'warning',
        })
      );
    });
}

/**
 * Get truelayer account standing orders
 */
export function getTrueLayerAccountStandingOrders(accountId: string) {
  api
    .get(`/api/truelayer/accounts/${accountId}/standing_orders`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(
          appSlice.actions.setAccountStandingOrders(response.data)
        );
      }
    });
}

/**
 * Get app info
 */
export function getAppInfo() {
  api.get(`/api/info`).then((response: AxiosResponse) => {
    if (response.data) {
      store.dispatch(appSlice.actions.setAppInfo(response.data));
    }
  });
}

/**
 * Get truelayer account standing orders
 */
export function getTrueLayerAccountDirectDebits(accountId: string) {
  api
    .get(`/api/truelayer/accounts/${accountId}/direct_debits`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('accessToken'),
      },
    })
    .then((response: AxiosResponse) => {
      if (response.data) {
        store.dispatch(appSlice.actions.setAccountDirectDebits(response.data));
      }
    });
}

// ... (previous imports and code)

/**
 * Get all spend by categories
 */
export function getAllCategoriesSpend() {
    api
        .get(`/api/transactions/aggregated/categories`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (response.data) {
                store.dispatch(appSlice.actions.setCategoriesSpend(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get categories spend data.',
                    type: 'warning',
                })
            );
        });
}

/**
 * Get all spend by merchants
 */
export function getAllMerchantsSpend() {
    api
        .get(`/api/transactions/aggregated/merchants`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (response.data) {
                store.dispatch(appSlice.actions.setMerchantsSpend(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get merchants spend data.',
                    type: 'warning',
                })
            );
        });
}

/**
 * Get all monthly spend
 */
export function getAllMonthlySpend() {
    api
        .get(`/api/transactions/aggregated/monthly-spend`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (response.data) {
                store.dispatch(appSlice.actions.setMonthlySpend(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get monthly spend data.',
                    type: 'warning',
                })
            );
        });
}

/**
 * Get all transaction types count
 */
export function getAllTransactionTypesCount() {
    api
        .get(`/api/transactions/aggregated/transaction-types`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('accessToken'),
            },
        })
        .then((response: AxiosResponse) => {
            if (response.data) {
                store.dispatch(appSlice.actions.setTransactionTypesCount(response.data));
            }
        })
        .catch(() => {
            store.dispatch(
                appSlice.actions.setErrorState({
                    enabled: true,
                    message: 'Failed to get transaction types count data.',
                    type: 'warning',
                })
            );
        });
}