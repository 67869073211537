import React, {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {LineChart} from '@mui/x-charts/LineChart';
import Modal from '@mui/material/Modal';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

const initialTotalAmount = 10000; // Total loan amount in USD
const months = Array.from({length: 12}, (_, i) => i + 1);
const remainingAmount = (month) => initialTotalAmount - (month * (initialTotalAmount / 12));

const Borrowing = () => {
    const [loans, setLoans]: any = useState([]);
    const [loanDetails, setLoanDetails] = useState({
        loanType: '',
        amount: '',
        interestRate: '',
        term: '',
    });
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoanDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoans(prevLoans => [...prevLoans, loanDetails]);
        setLoanDetails({
            loanType: '',
            amount: '',
            interestRate: '',
            term: '',
        });
        handleClose();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" gutterBottom>Borrowing</Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
            >
                Add New Loan
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        New Loan Details
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="loan-type-label">Loan Type</InputLabel>
                                    <Select
                                        labelId="loan-type-label"
                                        label="Loan Type"
                                        name="loanType"
                                        value={loanDetails.loanType}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="Car Finance">Car Finance</MenuItem>
                                        <MenuItem value="Personal Loan">Personal Loan</MenuItem>
                                        <MenuItem value="Student Loan">Student Loan</MenuItem>
                                        {/* Add more loan types as needed */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Amount"
                                    type="number"
                                    name="amount"
                                    value={loanDetails.amount}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Interest Rate (%)"
                                    type="number"
                                    name="interestRate"
                                    value={loanDetails.interestRate}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Loan Term (months)"
                                    type="number"
                                    name="term"
                                    value={loanDetails.term}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Add Loan
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>

            <Typography variant="h6" gutterBottom>Current Loans</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="loans table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Loan Type</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Interest Rate</TableCell>
                            <TableCell align="right">Term</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loans.map((loan, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {loan.loanType}
                                </TableCell>
                                <TableCell align="right">{loan.amount}</TableCell>
                                <TableCell align="right">{loan.interestRate}%</TableCell>
                                <TableCell align="right">{loan.term} months</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6" gutterBottom>Burn-Down Chart</Typography>
            <LineChart
                xAxis={[{ data: months, scaleType: 'linear' }]}
                series={[
                    {
                        data: months.map(remainingAmount),
                        label: 'Remaining Loan Amount',
                        showMark: false
                    }
                ]}
                width={500}
                height={300}
            />
        </Box>
    );
};

export default Borrowing;