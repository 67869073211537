import { Container, Grid, Paper, Typography } from '@mui/material';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState, AggregatedData } from '../../models/models';
import { classes } from './report.styles';
import {
    getAllCategoriesSpend,
    getAllMerchantsSpend,
    getAllMonthlySpend,
    getAllTransactionTypesCount,
} from "../../api/projectmyfinance.api";
import {format} from "date-fns";

export function Report() {
    const categoriesSpend = useSelector((state: AppState) => state.categoriesSpend || []);
    const merchantsSpend = useSelector((state: AppState) => state.merchantsSpend || []);
    const monthlySpend = useSelector((state: AppState) => state.monthlySpend || []);
    const transactionTypesCount = useSelector((state: AppState) => state.transactionTypesCount || []);

    useEffect(() => {
        getAllCategoriesSpend();
        getAllMerchantsSpend();
        getAllMonthlySpend();
        getAllTransactionTypesCount();
    }, []);

    const renderBarChart = (data: AggregatedData[], label: string) => (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <BarChart
                xAxis={[{ scaleType: 'band', data: data.map(d => d.label) }]}
                series={[
                    { data: data.map(d => d.value), label: label, color: "#007fff" }
                ]}
                width={500}
                height={300}
            />
        </Paper>
    );


    const renderLineChart = (data: AggregatedData[], label: string) => (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <LineChart
                xAxis={[
                    {
                        data: data.map(d => {
                            const date = new Date(d.label); // Assuming d.label is a string like "2024-01-01"
                            return format(date, 'MMM d'); // Formats to "Jan 1"
                        }),
                        scaleType: 'point' // Use 'point' for discrete data points
                    }
                ]}
                series={[
                    {
                        data: data.map(d => d.value),
                        label: label,
                        color: "#007fff"
                    }
                ]}
                width={500}
                height={300}
            />
        </Paper>
    );

    const renderPieChart = (data: AggregatedData[]) => (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <PieChart
                series={[
                    {
                        data: data.map(d => ({
                            id: d.label,
                            value: Math.abs(d.value),
                            label: d.label,
                            color: "#007fff"
                        })),
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: -30, additionalRadius: -30, color: 'gray' },
                        valueFormatter: (value) => `£${value.value.toFixed(2)}`,
                    }
                ]}
                slotProps={{
                    legend: {
                        labelStyle: {
                            tableLayout: 'fixed',
                        },
                        direction: 'column',
                        position: {
                            horizontal: 'right',
                            vertical: 'middle',
                        },
                    },
                }}
                height={350}
                margin={{ right: 350, top: 0 }}
                sx={{
                    '& .MuiPieChart-legendItem': {
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                    },
                    '& .MuiPieChart-legendLabel': {
                        fontWeight: 'bold',
                    },
                }}
            />
        </Paper>
    );

    return (
        <Container maxWidth="xl" sx={classes.container}>
            <Grid container spacing={2}>
                {categoriesSpend.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Spend by Category
                        </Typography>
                        {renderPieChart(categoriesSpend)}
                    </Grid>
                )}

                {merchantsSpend.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Spend by Merchant (Top 10)
                        </Typography>
                        {renderPieChart(merchantsSpend)}
                    </Grid>
                )}

                {monthlySpend.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Monthly Spend
                        </Typography>
                        {renderLineChart(monthlySpend, 'Total Spend')}
                    </Grid>
                )}

                {transactionTypesCount.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Transaction Types Count
                        </Typography>
                        {renderBarChart(transactionTypesCount, 'Number of Transactions')}
                    </Grid>
                )}

            </Grid>
        </Container>
    );
}