import {Box, Button, Container, Grid, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {getTruelayerAccount, getTruelayerCard} from '../../api/projectmyfinance.api';
import {AccountCard} from '../../components/account/accountCard';
import {CardCard} from '../../components/card/cardCard';
import {Account, AppState, Card} from '../../models/models';
import {classes} from './dashboard.styles';

export function Dashboard() {
  const navigate = useNavigate();
  const accounts = useSelector((state: AppState) => state.accounts);
  const partnerAccounts = useSelector((state: AppState) => state.partnerAccounts);
  const accountData = useSelector((state: AppState) => state.accountData);
  const cards = useSelector((state: AppState) => state.cards);
  const partnerCards = useSelector((state: AppState) => state.partnerCards);
  const cardData = useSelector((state: AppState) => state.cardData);
  const authLink = process.env.REACT_APP_AUTH_LINK;

  useEffect(() => {
    if (accounts) {
      accounts.forEach(account => {
        getTruelayerAccount(account.accountId);
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (partnerAccounts) {
      partnerAccounts.forEach(account => {
        getTruelayerAccount(account.accountId);
      });
    }
  }, [partnerAccounts]);

  useEffect(() => {
    if (cards) {
      cards.forEach(card => {
        getTruelayerCard(card.cardId);
      });
    }
  }, [cards]);

  useEffect(() => {
    if (partnerCards) {
      partnerCards.forEach(card => {
        getTruelayerCard(card.cardId);
      });
    }
  }, [partnerCards]);

  return (
      <Container maxWidth="xl" sx={classes.container}>
        {/* Personal Accounts Section */}
        {accounts && <Box marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Accounts
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {accounts && accounts.map((account: Account, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`account-${i}`}>
                  <AccountCard
                      index={i}
                      accountData={accountData?.find(accountData => accountData.account_id === account.accountId)}
                      openTransactions={() => {
                        navigate(`/transactions/account/${account.accountId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>}

        {/* Personal Cards Section */}
        {cards && <Box marginTop={4} marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Credit Cards
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {cards && cards.map((card: Card, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`card-${i}`}>
                  <CardCard
                      index={i}
                      cardData={cardData?.find(cardData => cardData.account_id === card.cardId)}
                      openTransactions={() => {
                        navigate(`/transactions/card/${card.cardId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>}

        {/* Partner Accounts Section */}
        {partnerAccounts && <Box marginTop={4} marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Partner Accounts
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {partnerAccounts && partnerAccounts.map((account: Account, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`partner-account-${i}`}>
                  <AccountCard
                      index={i}
                      accountData={accountData?.find(accountData => accountData.account_id === account.accountId)}
                      openTransactions={() => {
                        navigate(`/transactions/account/${account.accountId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>}

        {/* Partner Cards Section */}
        {partnerCards && <Box marginTop={4} marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Partner Credit Cards
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {partnerCards && partnerCards.map((card: Card, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`partner-card-${i}`}>
                  <CardCard
                      index={i}
                      cardData={cardData?.find(cardData => cardData.account_id === card.cardId)}
                      openTransactions={() => {
                        navigate(`/transactions/card/${card.cardId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>}

        {/* Connect Your Bank Account Button */}
        {authLink && (
            <Grid container justifyContent="center" marginTop={2} marginBottom={2}>
              <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={authLink}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#125ea8',
                    },
                  }}
              >
                Connect Your Bank Account
              </Button>
            </Grid>
        )}
      </Container>
  );
}